@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@font-face {
  font-family: 'RocketFont';
  src: url(../fonts/Rocketfont.woff2);
}
:root{
  --rocket-font: 'RocketFont';
  --card-color: #fff;;
  --body-text-color: #000;
  --card-shadow:0 6px 10px 0 rgba(75,155,224,.14), 0 1px 18px 0 rgba(75,155,224,.12), 0 3px 5px -1px rgba(75,155,224,.2);
  --card-borr: 20px;
  --accent-color: #137cf9;
  --accent-gradient: linear-gradient(102deg,#3aa0ff -13%,#137cf9 50%,#0152f9 133%);
  --green-color: #15ed6b;
  --grey-color: #94a6cd;
  --content-bg-color: #141b2f;
  --orange-gradient: linear-gradient(103.36deg, rgb(247, 112, 0), rgb(247, 45, 0));
  --blue-text-color:rgb(26, 150, 253); 
  --transition: all .2s ease;
}
body{
  font-family: 'Montserrat', sans-serif;
  background: var(--card-color);
  color: var(--body-text-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.03em;
  overflow-x: hidden;
}
.swiper-container{
  border-radius: var(--card-borr);
}
.animate__fadeIn{
  animation-duration: .2s !important;
}
body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
	border-radius: 10px;
	background-color: transparent;
}

body::-webkit-scrollbar {
	width: 5px;
	height: 4px;
	background-color: transparent;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
	background-color: #505562;
}
/* SWAL */
.animate__animated.animate__fadeInRight {
	--animate-duration: .5s;
}
.animate__animated.animate__fadeOutRight {
	--animate-duration: .5s;
}
.animate__animated.animate__fadeInRight {
	--animate-duration: 1s;
}
.modal_content {
	color: #000!important;
}
.modal_content_error_bet {
	padding: 10px 20px!important;
}
.modal_content_error_bet .swal2-html-container{
	color: #000;
}
.modal_content_error_bet .swal2-html-container img{
	margin-right: 5px !important;
}
.swal_modal_container{
	color: #000 !important;
}
.modal_popup {
	border-radius: 20px !important;
}
.modal_icon.swal2-success [class^=swal2-success-line] {
	background-color: #15ed6b!important;
}

.modal_icon.swal2-success .swal2-success-ring {
	border: .25em solid rgba(0,227,150,.3)!important;
}
.overflowNone{
	overflow:hidden  !important;
}