
.iconToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  //overflow: hidden;
  background-color: #ffffff; /* Цвет WhatsApp */
  cursor: pointer;
  position: fixed;
  z-index: 6;
  bottom: 20px;
  right: 30px;



}

.icon:hover{
  transform: scale(1.2);
}

.icon {
  display: flex;
  transition: transform 0.3s ease-in-out;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;

}

.whatsapp {
  font-size: 50px;
  color: green;
  /*animation: wpAnimation 5s infinite;*/
  position: absolute;


}

.telegram {
  font-size: 50px;
  color: #0088cc; /* Цвет Telegram */
  /*animation: tgAnimation 5s infinite;*/

}

.ripple {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(116, 255, 116, 0.4); /* Прозрачный белый цвет для волн */
  transform: scale(0);
  animation: rippleAnimation 5s infinite;
  cursor: default;
  z-index: 1;
}

.tgRipple{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 136, 204, 0.4); /* Прозрачный белый цвет для волн */
  transform: scale(0);
  animation: rippleAnimation 5s infinite;
  cursor: default;
  z-index: 1;
  animation-delay: 2.5s;
}

@keyframes wpAnimation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes tgAnimation {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes rippleAnimation {
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.modal{
  display: none;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

}

.modalContent{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 40px 30px 20px;
  position: relative;
  width:427px;
  animation: modal-show 0.3s ease-out;


}

.title{
  color: #333;

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;

}

.btns{
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.btn{
  padding: 10px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  border-radius: 5px;
  background: #0D99FF;
  cursor: pointer;
  text-decoration: none;

}

.btn:hover{
  opacity: 0.8;
}

.btnText{
  color: #fff;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  

}

.modalWPicon{
  font-size: 30px;
  color: #FFFFFF;


}

.modalTGicon{
  font-size: 30px;
  color: #FFFFFF;

}

.closeIcon{
  cursor: pointer;
  font-size: 30px;
}

.closeIconWrap{
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes modal-show {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal-hide {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.show {
  display: flex;
}

@media screen and (max-width: 1100px) {
  .iconToggle{
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
  }

  .whatsapp{
    font-size: 30px;
  }
  .telegram{
    font-size: 30px;
  }
  .modalContent{
    width: 90%;
    padding: 30px 30px 20px;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .btns{
    gap: 10px;
  }
  .btn{
    padding: 7px 0;
  }
  .btnText{
    font-size: 16px;
  }
  .modalWPicon{
    font-size: 20px;
  }
  .modalTGicon{
    font-size: 20px;
  }
}