.RequisitesPage{
    padding-bottom: 50px;
}

.headerWrap{
    background-color: #137CF9;
}

.title{
    padding-top: 70px;
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subTitle{
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-top: 20px;
}

.downloadBtn{
    border-radius: 10px;
    background: #137CF9;
    padding: 10px 20px;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    cursor: pointer;
}

.dFlex{
    display: flex;
}

.table{
    margin-top: 50px;
}

.tableItem{
    display: flex;
    align-items: center;

}

.tableItemTitle{
    flex: 1;
    padding: 20px 0;
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tableItemInfo{
    flex: 2;
    padding: 20px 0;
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 767px) {
    .tableItem{
        flex-direction: column;
        padding: 0 10px;
    }
}